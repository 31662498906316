interface ResponseError {
  children: string[];
  constraints: any;
  property: string;
  value: any;
}
const validationError = async (validation: any) => {
  if (typeof validation === "string") {
    return {type: "string", validation};
  } else if (typeof validation === "object") {
    // const key = validation.map((v: ResponseError) => {
    //   return { [v.property]: v.constraints };
    // });
    console.log(validation);
    // const renew = await validation.reduce((result: any, current: ResponseError) => {
    //   // const obj = { [current.property]: current.constraints };
    //   result[current.property] = current.constraints;
    //   return result;
    // }, {});
    return {type: "array", validation: validation};
  } else {
    return {type: "error", validation: "error"};
  }
};

export default validationError;
