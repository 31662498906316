<template>
  <div class="container mt-5 reset-password-box">
    <h1 class="font-weight-bold">Reset Password</h1>
    <a-form layout="vertical">
      <a-form-item label="username or email">
        <a-input v-model="identity" placeholder="username or email" />
        <validate-error v-if="validate.identity" :errors="validate.identity" />
      </a-form-item>
      <a-form-item label="new password">
        <a-input
          v-model="newPassword"
          type="password"
          placeholder="new password"
        />
        <validate-error
          v-if="validate.newPassword"
          :errors="validate.newPassword"
        />
      </a-form-item>
      <a-form-item label="confirm password">
        <a-input
          v-model="confirmPassword"
          type="password"
          placeholder="confirm password"
        />
        <validate-error
          v-if="validate.confirmPassword"
          :errors="validate.confirmPassword"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isProcessing" @click="resetPassword">
          Submit
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import validationError from "@/helpers/display-error";
import ValidateError from "@/components/common/ValidateError";
import { get } from "vuex-pathify";
export default {
  components: { ValidateError },
  data() {
    return {
      identity: null,
      newPassword: null,
      confirmPassword: null,
      validate: {},
      isProcessing: false
    };
  },
  computed: {
    user: get("auth/user")
  },
  mounted() {
    this.identity = this.user.email;
  },
  methods: {
    async resetPassword() {
      this.isProcessing = true;
      const { identity, newPassword, confirmPassword } = this;
      const { error, validation } = await AdminService.resetPassword({
        identity,
        newPassword,
        confirmPassword
      });
      if (error) {
        const validate = await validationError(validation);
        if (validate.type === "string") {
          this.$message.error(validate.validation);
        } else {
          this.validate = validate.validation;
          this.$message.error(`${Object.keys(this.validate)} is required`);
        }
      } else {
        this.$message.success("Reset Password Successfull");
        this.$router.push("/login");
      }
      this.isProcessing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.reset-password-box {
  max-width: 540px;
}
</style>
